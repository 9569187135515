






import { Component, Prop, Vue, Provide } from "vue-property-decorator";

@Component
export default class DocsSamplePage extends Vue {
  
 
}
